<template>
  <div class="view view--references">

    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <h1 class="page-title">Référentiel - {{ pageTitle }}</h1>
      </div>
    </div>

    <div class="view-body">
      <!--
      All urls point to the same component,
      aka ReferencesView,
      this avoids having sub-views for all categories

      If the name of the category has to be changed,
      you will have to go in the route file admin/index.js,
      and change the corresponding url in the children
      -->
      <TabsMaterial
        :tabs="[
          {
            urlName: 'referentialConditionsTraitement',
            text: 'Fiches conditions de traitement'
          },
          { urlName: 'referentialParcels', text: 'Fiches parcellaires' },
          { urlName: 'referentialEssais', text: 'Fiches liées aux essais' },
          { urlName: 'referentialProducts', text: 'Produits' },
          { urlName: 'referentialProtocols', text: 'Protocoles' },
          { urlName: 'referentialPesee', text: 'Fiches pesée' },
          { urlName: 'referentialSubThemes', text: 'Sous-thèmes' },
          { urlName: 'referentialDivers', text: 'Divers' },
        ]"
      />
      <Section>
        <Container>
          <div class="grid">
            <!--
            When we click on one of the tabs,
            we take the last segment of the url (ex: conditions-traitement)
            and the system automatically determines the category to associate,
            so we pass all this to the ReferencesSubview
            -->
            <Btn
              v-for="referential in getFilteredReferences()"
              :key="referential.id"
              :text="referential.designation"
              color="primary"
              icon="chevron-big-right"
              hollow
              iconAfter
              @click="getToItem({ type:'referentiel', id:referential.id })"
            />
            <template
              v-if="getLastSegmentInUrl() === 'sous-themes'"
            >
              <Btn
                v-for="thematique in thematiques"
                :key="thematique.id"
                :text="thematique.designation"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToSubTheme({ type:'thematiques', id:thematique.id })"
              />
            </template>

            <template
              v-if="getLastSegmentInUrl() === 'produits'"
            >
              <Btn
                text="Pictogrammes"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToItem({ type: 'pictograms' })"
              />
              <Btn
                text="Catégories de fichier"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToItem({ type: 'file-categories' })"
              />
              <Btn
                text="Unités"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToItem({ type:'unites' })"
              />
              <Btn
                text="Familles de produits"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToItem({ type:'familles-de-produits' })"
              />
              <Btn
                text="Familles de cultures"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToItem({ type:'familles-de-cultures' })"
              />
              <Btn
                text="Cultures"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToItem({ type:'cultures' })"
              />
              <Btn
                text="Composants"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToItem({ type: 'composants' })"
              />
              <Btn
                text="Stade"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToItem({ type: 'stades' })"
              />
            </template>

            <template
              v-if="getLastSegmentInUrl() === 'divers'"
            >
              <Btn
                text="Pictogrammes"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToItem({ type: 'pictograms' })"
              />
              <Btn
                text="Catégories de fichier"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToItem({ type: 'file-categories' })"
              />
              <Btn
                text="Médias"
                color="primary"
                icon="chevron-big-right"
                hollow
                iconAfter
                @click="getToItem({ type: 'medias' })"
              />
            </template>
          </div>
        </Container>
      </Section>
    </div>
  </div>

</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import TabsMaterial from '@/components/layout/TabsMaterial.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'ReferencesView',

  components: {
    Btn,
    TabsMaterial,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      references: [],
      thematiques: [],
      componentKey: 0,
    }
  },

  mounted() {
    this.getReferences()
    this.getThematiques()
  },

  methods: {
    getToItem(params) {
      this.$router.push({ name: 'referential', params })
    },
    getToSubTheme(params) {
      this.$router.push({ name: 'thematique', params })
    },
    getReferences() {
      this.emitter.emit('open-loader')
      this.fetchService.get('dictionnaire/referentiel', {
        limit: 0,
        sort: 'uid.ASC',
      }).then((response) => {
        this.references = response.data

        this.componentKey += 1

        this.emitter.emit('close-loader')
      })
    },
    getThematiques() {
      this.fetchService.get('dictionnaire/thematique', { limit: '0' })
        .then((response) => {
          this.thematiques = response.data
        })
    },
    getLastSegmentInUrl() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1)
    },
    getFilteredReferences() {
      return this.references
        .filter((reference) => reference.categorie === this.getLastSegmentInUrl())
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
