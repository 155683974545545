<template>
  <div class="view">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
        <Btn
          class="action-btn"
          icon="create"
          color="primary"
          text="Ajouter une sous-catégorie"
          :to="{
            name: 'fileSubCategoryAdd',
            params: {
              type: 'file-categories',
              id: fileCategoryId,
              action: 'add'
            }
        }"
        />
      </div>
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          round
          grow
          icon="arrow-left"
          :to="{
            name: 'referential',
            params: { type: 'file-categories' }
          }"
        />
        <h1 class="page-title">Référentiel - {{ pageTitle}}</h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section section--list">
        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
          loop-on="enfants"
        >
          <template v-slot:group-actions="{ selectedItems }">
            <Btn
              text="Supprimer"
              color="default"
              @click="openModal('deleteItems', selectedItems)"
            />
          </template>

          <template v-slot:actions="{ item }">
            <Btn
              icon="trash-alt"
              grow
              round
              @click="openModal('deleteItem', item)"
            />
            <Btn
              default
              :to="{
                name: 'fileSubCategoryEdit',
                params: { id: fileCategoryId, sId: item.id, action: 'edit' },
              }"
              icon="create"
              round
              grow
            />
          </template>
        </List>
      </Section>
      <Modal
        title="Supprimer la sous-catégorie&nbsp;?"
        :active="modal.deleteItem"
        :data="modalData"
        @modal-close="modal.deleteItem = false"
      >
        <template v-slot:modal-body="{ data }">
          <p>
            Voulez vous vraiment supprimer la sous-catégorie
            <b>{{ data.valeur }}</b
            >&nbsp;?
          </p>
        </template>
        <template v-slot:modal-footer="{ data }">
          <Btn text="Annuler" @click="modal.deleteItem = false" />
          <Btn
            text="Supprimer"
            @click="deleteItems([data.id])"
            icon="trash-alt"
            color="primary"
          />
        </template>
      </Modal>
      <Modal
        title="Supprimer des sous-catégorie&nbsp;?"
        :active="modal.deleteItems"
        :data="modalData"
        @modal-close="modal.deleteItems = false"
      >
        <template v-slot:modal-body="{ data }">
          <p>
            Voulez vous vraiment supprimer
            {{ data.length }} sous-catégories&nbsp;?
          </p>
        </template>
        <template v-slot:modal-footer="{ data }">
          <Btn text="Annuler" @click="modal.deleteItems = false" />
          <Btn
            text="Supprimer"
            @click="deleteItems(data)"
            icon="trash-alt"
            color="primary"
          />
        </template>
      </Modal>

    </div>
  </div>
</template>

<script>
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'SuppliersView',

  components: {
    List,
    Pagination,
    Btn,
    Section,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      fileCategoryId: this.$route.params.id,
      subFileCategoryId: this.$route.params.sId,
      list: {
        api: {
          endpoint: `dictionnaire/categorie/fichier/${this.$route.params.id}`,
          params: {
            sort: 'nom.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Description', dataField: 'nom' },
        ],
        col: ['nom'],
        key: 'id',
      },
      data: [],
      modalData: {},
      modal: {
        deleteItem: false,
        deleteItems: false,
      },
    }
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        this.fetchService.delete(`dictionnaire/categorie/fichier/${item.id}`).then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: `La catégorie de fichier ${item.nom} a bien été supprimé.`,
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )
      })

      this.modal.deleteItem = false
      this.modal.deleteItems = false
      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
    },
  },
}
</script>

<style lang="scss" scoped>
.role-profile {
  padding: $gutter-half;
}

.list-row {
  border: 1px solid grey;
}
</style>
