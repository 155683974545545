<template>
  <div class="view view--variable">
    <div class="view-header">
      <div class="view-header__header">
        <Btn
          v-show="helperService.userHasPermission('variable_edit')"
          :to="{ name: 'variableEdit', params: { id: id } }"
          class="action-btn"
          text="Modifier la variable"
          icon="create"
          color="primary"
        />
      </div>
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          :to="{ name: 'variables' }"
          round
          grow
          icon="arrow-left"
        />
        <h1 v-if="data" class="page-title" v-html="`Variable : ${data.designation}`"></h1>
      </div>
    </div>
    <div
      class="view-body"
    >

      <Section v-if="data">
        <Container>
          <Section>
            <div class="form grid">
              <div class="tmp-col">
                <KeyValue label="Désignation" :value="data.designation"/>
              </div>
              <div class="tmp-col">
                <KeyValue label="Type de variable" :value="data.type?.designation"/>
              </div>
            </div>
          </Section>
          <Section v-show="data.type?.uid === 'DECIMAL'">
            <div class="form grid">
              <div class="tmp-col">
                <KeyValue label="Valeur min." :value="data.valeur_min"/>
              </div>
              <div class="tmp-col">
                <KeyValue label="Valeur max." :value="data.valeur_max"/>
              </div>
              <div class="tmp-col">
                <KeyValue label="Unité" :value="data.unite?.valeur"/>
              </div>
            </div>
          </Section>
          <Section v-show="data.type?.uid === 'LIST' && repeatables?.valeurs">
            <div class="form grid">
              <div class="col--fullwidth">
                <KeyValue label="Valeurs">
                  <RepeatableValues
                    :labels="['Designation']"
                    :values="repeatables?.valeurs"
                    :n-cols="1"
                  />
                </KeyValue>
              </div>
            </div>
          </Section>
          <Section>
            <div class="form grid">
              <div class="tmp-col">
                <KeyValue label="Localisation de la notation"
                          :value="data.laboratoire ? 'En laboratoire' : 'Sur le terrain'"/>
              </div>
            </div>
          </Section>

        </Container>
      </Section>

    </div>

  </div>

</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import RepeatableValues from '@/components/form/RepeatableValues.vue'

export default {
  name: 'VariableView',

  components: {
    Btn,
    Section,
    Container,
    KeyValue,
    RepeatableValues,
  },

  data() {
    return {
      data: null,
      id: null,
      repeatables: {},
    }
  },

  async created() {
    this.id = Number(this.$route.params.id)
    this.getData()
  },

  methods: {
    getData() {
      this.emitter.emit('open-loader')
      this.fetchService.get(`variable/${this.id}`).then((response) => {
        this.data = response.data
        this.fetchService.get(`variable/${this.id}/valeur`).then((r) => {
          this.data.valeurs = r.data
          this.repeatables.valeurs = r.data.map((value) => ({
            Désignation: value.designation,
          }))
        })
      })
      this.emitter.emit('close-loader')
    },
  },

}
</script>

<style lang="scss" scoped>
</style>
