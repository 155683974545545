<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--variable-add-edit">
      <div class="view-header">
        <div class="view-header__header">
          <Btn
            v-show="helperService.userHasPermission('variable_remove') && variable.id"
            class="action-btn"
            text="Supprimer la variable"
            icon="trash-alt"
            color="primary"
            @click="openModal('delete', variable)"
          />
        </div>

        <div class="view-header__footer">
          <Btn
            class="back-btn"
            :to="{ name: 'variables' }"
            round
            grow
            icon="arrow-left"
          />
          <h1 class="page-title" v-html="pageTitle"></h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>
            <Input id="designation" label="Désignation" required />
            <SelectExtended
              id="type_uid"
              label="Type de variable"
              option-key="uid"
              option-value="designation"
              api-endpoint="dictionnaire/type/variable"
              @update:modelValue="changeType($event)"
            />
            <Input v-show="typeIsNumeric" id="valeur_min" label="Valeur min."/>
            <Input v-show="typeIsNumeric" id="valeur_max" label="Valeur max."/>
            <SelectExtended
              v-show="typeIsNumeric"
              id="unite_uid"
              label="Unité"
              option-key="uid"
              option-value="valeur"
              api-endpoint="unite"
            />

            <RepeatableInputs
              v-show="typeIsList"
              v-if="repeatables.valeurs && repeatables.valeurs.default"
              label="Valeurs"
              id="valeurs"
              :errors="errors"
              :default-selection="repeatables.valeurs.default"
              :items="repeatables.valeurs.items"
              :n-cols="1"
            />
            <RepeatableInputs
              v-show="typeIsList"
              v-else-if="repeatables.valeurs"
              label="Valeurs"
              id="valeurs"
              :errors="errors"
              :items="repeatables.valeurs.items"
              :n-cols="1"
            />

            <Radio
              id="laboratoire"
              :items="[
                    { label: 'En laboratoire', value: true },
                    { label: 'Sur le terrain', value: false },
                  ]"
              label="Localisation de la notation"
              inputStyle="inline"
            />
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn :to="{ name: 'variables' }" text="Annuler" />
          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>
    <Modal
      title="Supprimer la variable&nbsp;?"
      :active="modal.delete"
      :data="modalData"
      @modal-close="modal.delete = false"
    >
      <template v-slot:modal-body="{ data }">
        <p>
          Voulez vous vraiment supprimer la variable
          <b>{{ data?.designation }}</b
          >?
        </p>
      </template>
      <template v-slot:modal-footer>
        <Btn text="Annuler" @click="modal.delete = false" />
        <Btn
          text="Supprimer"
          @click="deleteVariable()"
          icon="trash-alt"
          color="primary"
        />
      </template>
    </Modal>
  </form>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import RepeatableInputs from '@/components/form/RepeatableInputs.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'VariableAddEditView',
  components: {
    SelectExtended,
    Radio,
    Input,
    Btn,
    Section,
    Container,
    RepeatableInputs,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      designation: this.yup.string().required(),
      type_uid: this.yup.object().nullable(),
      unite_uid: this.yup.object().nullable(),
      valeur_min: this.yup.string().nullable(),
      valeur_max: this.yup.string().nullable(),
      laboratoire: this.yup.boolean().nullable(),
      valeurs: this.yup.array().of(
        this.yup.object().shape({
          designation: this.yup.string().nullable(),
        }),
      ).nullable(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      modal: {
        delete: false,
      },
      modalData: null,
      repeatables: {
        valeurs: {
          items: {
            group: [
              {
                label: 'Désignation',
                name: 'designation',
              },
            ],
          },
        },
      },
      schema: null,
      variable: {},
      vId: this.$route.params.id ?? null,
      onSubmit,
      errors,
      isSubmitting,
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  computed: {
    typeIsList() {
      return this.variable && this.variable.isList
    },
    typeIsNumeric() {
      return this.variable && this.variable.isNumeric
    },
    type: {
      get() {
        return true
      },
      set(v) {
        this.variable.isNumeric = false
        this.variable.isList = false
        if (v.key === 'DECIMAL') {
          this.variable.isNumeric = true
        }
        if (v.key === 'LIST') {
          this.variable.isList = true
        }
      },
    },
  },

  mounted() {
    if (this.vId) {
      this.emitter.emit('open-loader')
      this.getVariable()
      this.emitter.emit('close-loader')
    }
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
    deleteVariable() {
      this.emitter.emit('open-loader')
      if (this.variable.valeurs) {
        this.variable.valeurs.forEach((value) => {
          this.fetchService.delete(`variable/${this.vId}/valeur/${value.id}`).then(() => {
            console.log(`Valeur ${value.id} supprimée`)
          })
          this.variable.valeurs.shift()
        })
      }
      if (this.variable.valeurs.length === 0) {
        this.fetchService.delete(`variable/${this.vId}`).then(() => {
          this.emitter.emit('list-refresh')
          this.emitter.emit('close-loader')
          this.emitter.emit('alert', {
            type: 'success',
            content: this.vId ? 'La variable a bien été modifiée.' : 'La variable a bien été créée.',
          })

          this.$router.push({ name: 'variables' })
        })
      }
    },
    changeType(event) {
      this.type = event
    },
    getVariable() {
      this.fetchService.get(`variable/${this.vId}`).then((response) => {
        this.variable = response.data
        this.fetchService.get(`variable/${this.vId}/valeur`).then((valeurs) => {
          this.repeatables.valeurs.default = valeurs.data.map((valeur) => ({
            id: valeur.id,
            designation: valeur.designation,
          }))
          this.variable.valeurs = valeurs.data.map((valeur) => ({
            id: valeur.id,
            designation: valeur.designation,
          }))
        })

        this.variable.isNumeric = this.variable?.type?.uid === 'DECIMAL'
        this.variable.isList = this.variable?.type?.uid === 'LIST'
        this.schema = this.formService.populateShema(this.variable)
        this.schema.type_uid = {
          key: this.variable?.type?.uid,
          value: this.variable?.type?.designation,
        }
        this.schema.unite_uid = {
          key: this.variable?.unite?.uid,
          value: this.variable?.unite?.valeur,
        }
        // set form values
        this.formService.setFormValues(this.schema)
      })
    },

    getValeursToUpdate(items = []) {
      return items.filter((item) => 'id' in item)
    },
    getValeursToCreate(items = []) {
      return items.filter((item) => item.id === undefined)
    },
    getValeursToDelete(items = []) {
      if (this.variable?.valeurs) {
        return this.variable.valeurs.filter((item) => {
          const ids = items.map((i) => i.id)
          return !ids.includes(item.id)
        })
      }

      return []
    },

    handleValues(values) {
      // prepare the data for API
      this.emitter.emit('open-loader')
      const endpoint = this.vId ? `variable/${this.vId}` : 'variable'
      const method = this.vId ? 'put' : 'post'
      const formValues = { ...values }
      formValues.unite_uid = values.unite_uid?.key
      formValues.type_uid = values.type_uid?.key
      formValues.uid = this.helperService.generateUid(values.designation)

      if (formValues.type_uid !== 'LIST') {
        formValues.valeurs = undefined
      }

      if (formValues.type_uid !== 'DECIMAL') {
        formValues.unite_uid = null
        formValues.valeur_max = null
        formValues.valeur_min = null
      }

      const valeursToUpdate = this.getValeursToUpdate(formValues.valeurs)
      const valeursToCreate = this.getValeursToCreate(formValues.valeurs)
      const valeursToDelete = this.getValeursToDelete(formValues.valeurs)

      this.fetchService[method](endpoint, formValues).then((result) => {
        const { id } = result.data
        if (formValues.valeurs) {
          if (!this.vId) {
            formValues.valeurs.forEach((valeur) => {
              this.fetchService.post(`variable/${id}/valeur`, valeur).then((res) => {
                console.log(`Valeur ${res.data.id} créée`)
              })
            })
          } else {
            valeursToDelete.forEach((valeur) => {
              this.fetchService.delete(`variable/${id}/valeur/${valeur.id}`).then(() => {
                console.log(`Valeur ${valeur.id} supprimée`)
              })
            })
            valeursToUpdate.forEach((valeur) => {
              this.fetchService.put(`variable/${id}/valeur/${valeur.id}`, valeur).then(() => {
                console.log(`Valeur ${valeur.id} modifiée`)
              })
            })
            valeursToCreate.forEach((valeur) => {
              this.fetchService.post(`variable/${id}/valeur`, valeur).then((res) => {
                console.log(`Valeur ${res.data.id} créée`)
              })
            })
          }
        } else if (valeursToDelete.length) {
          valeursToDelete.forEach((valeur) => {
            this.fetchService.delete(`variable/${id}/valeur/${valeur.id}`).then(() => {
              console.log(`Valeur ${valeur.id} supprimée`)
            })
          })
        }

        this.emitter.emit('alert', {
          type: 'success',
          content: this.vId ? 'La variable a bien été modifiée.' : 'La variable a bien été créée.',
        })

        this.$router.push({ name: 'variables' })
        this.emitter.emit('close-loader')
      })
    },
  },
}
</script>
