<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--models-label-add-edit">
      <div class="view-header">
        <div class="view-header__footer">
          <Btn
            class="back-btn"
            :to="{ name: 'statutLabelValues' }"
            round
            grow
            icon="arrow-left"
          />
          <h1 class="page-title" v-html="pageTitle"></h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>
            <Input id="designation" label="Désignation" required />
            <Input id="attribut" label="Attribut" />
            <Radio
              id="special"
              required
              :items="[
                    { label: 'Oui', value: true },
                    { label: 'Non', value: false },
                  ]"
              label="Spécial"
              inputStyle="inline"
            />
            <SelectExtended
              id="format_uid"
              label="Format de valeur d'étiquette"
              option-key="uid"
              option-value="designation"
              api-endpoint="dictionnaire/type/etiquette"
            />
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn :to="{ name: 'templatesLabel' }" text="Annuler" />
          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'StatutLabelValueAddEditView',
  components: {
    SelectExtended,
    Radio,
    Input,
    Btn,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      designation: this.yup.string().required(),
      attribut: this.yup.string().nullable(),
      special: this.yup.boolean().required(),
      format_uid: this.yup.object().nullable(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      templateLabel: {},
      templateLabelId: this.$route.params.id ?? null,
      data: [],
      entity: [],
      onSubmit,
      errors,
      isSubmitting,
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  mounted() {
    if (this.templateLabelId) {
      this.emitter.emit('open-loader')
      this.getTemplateLabel()
      this.emitter.emit('close-loader')
    }
  },

  methods: {
    getTemplateLabel() {
      this.fetchService.get(`dictionnaire/etiquette/gabarit/${this.templateLabelId}`).then((response) => {
        this.templateLabel = response.data

        this.schema = this.formService.populateShema(this.templateLabel)

        this.fetchService.get(`dictionnaire/etiquette/gabarit/${this.templateLabel.id}/zone`).then((zones) => {
          console.log(zones.data)
          this.repeatables.zones.default = zones.data.map((zone) => ({
            x: this.formatNumber(zone.x),
            y: this.formatNumber(zone.y),
            largeur: this.formatNumber(zone.largeur),
            hauteur: this.formatNumber(zone.hauteur),
          }))

          console.log(this.repeatables.zones.default)
        })

        // set form values
        this.formService.setFormValues(this.schema)
      })
    },

    handleValues(values) {
      // prepare the data for API
      this.emitter.emit('open-loader')

      this.fetchService.post('dictionnaire/type/etiquette/valeur', {
        uid: this.helperService.generateUid(values.designation),
        designation: values.designation,
        attribut: values.attribut,
        special: values.special,
        format_uid: values.key,
      }).then(() => {
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Le statut de type d\'étiquette a bien été créé.',
        })

        this.$router.push({ name: 'statutLabelValues' })
      })

      this.emitter.emit('close-loader')
    },

    formatNumber(value) {
      return parseInt(value, 10)
    },
  },
}
</script>
