<template>
  <div class="view view--product">
    <div class="view-header">
      <div class="view-header__footer">
        <Btn class="back-btn" :to="{ name: 'modelsLabel' }" round grow icon="arrow-left" />
        <h1 class="page-title" v-html="`Modèle d'étiquette : ${data.modele?.nom}`"></h1>
      </div>
    </div>

    <div class="view-body">

      <Section>
        <Container>
          <Section v-if="data.modele">

            <div class="form grid">
              <div class="tmp-col">
                <KeyValue label="Nom du modèle d'étiquette" :value="data.modele.nom" />
              </div>
              <div class="tmp-col">
                <KeyValue label="Type d'étiquette" :value="data.modele.type.designation" />
              </div>
              <div class="tmp-col">
                <KeyValue label="Gabarit d'étiquette" :value="data.modele.gabarit.designation" />
              </div>
            </div>

          </Section>

          <Section class="section-modele" v-if="data.zones && '0' in data.zones && data.zones[0].zone_format">
            <div class=" modele modele-grid">
              <template v-for="(n, index) in data.zones" :key="index">
                <div class="modele-grid-index" :class="`modele-grid-index--${index}`" v-if="n.zone_format" :style="{
                  position: 'relative',
                  gridColumn: +n.zone_format.x + '/' +
                    (n.zone_format.largeur + n.zone_format.x),
                  gridRow: +n.zone_format.y + '/' +
                    (n.zone_format.hauteur + n.zone_format.y),
                  backgroundColor: '#FFF',
                  border: '1px solid'
                }">
                  <span class="modele-grid__index">{{+index + 1 }}</span>
                  <div class="modele-grid__content">
                    <p>
                      {{ n.contenu?.type_valeur_etiquette }}
                    </p>
                  </div>

                </div>
              </template>
            </div>
          </Section>

        </Container>
      </Section>

    </div>

    <div class="view-footer">
      <div class="view-actions">
        <Btn v-if="helperService.userHasPermission('etiquettemodele_edit')"
          :to="{ name: 'modelLabelEdit', params: { id: id } }" class="action-btn" text="Modifier le modèle d'étiquette"
          icon="create" color="primary" />
      </div>
    </div>
  </div>

</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import KeyValue from '@/components/form/KeyValue.vue'

export default {
  name: 'ModelLabelView',

  components: {
    Btn,
    Section,
    Container,
    KeyValue,
  },

  data() {
    return {
      data: [],
      id: null,

    }
  },
  computed: {
    LE() { return this.data?.etiquette?.L },
    HE() { return this.data?.etiquette?.H },
    le() { return this.data?.etiquette?.l },
    he() { return this.data?.etiquette?.h },
    ratio() { return this.data?.etiquette?.L / this.data?.etiquette?.H },
  },

  async created() {
    this.id = Number(this.$route.params.id)
    await this.getData()
    this.$nextTick(() => {
      Object.keys(this.data.zones).forEach((k) => {
        this.calculateFontSize(this.data.zones[k], k)
      })
    })
  },

  unmounted() {
    this.data = []
  },

  methods: {

    calculateFontSize(zone, index, step = 2) {
      // Sélection du conteneur et initialisation des variables
      const $container = document.querySelector(`.modele-grid-index--${index}`)
      let $content = null
      let baseFontSizeValue = zone.contenu?.facteur_taille_texte || 3
      let minFontSize = 1 // Valeur de départ pour la taille minimale en pixels
      const maxFontSize = 200 // Limite maximale de la taille de police

      // Calcul pour le front-end
      if ($container) {
        $content = $container.querySelector('p')
        if ($content) {
          $content.style.fontSize = `${minFontSize}px`
          $content.style.color = zone.contenu?.couleur || 'black'
          if (zone.contenu?.gras || zone.contenu?.italique) {
            $content.style.fontWeight = zone.contenu?.gras ? 'bold' : 'normal'
            $content.style.fontStyle = zone.contenu?.italique ? 'italic' : 'normal'
          } else {
            $content.style.fontWeight = 'inherit'
            $content.style.fontStyle = 'inherit'
          }
          $content.style.textTransform = 'uppercase'
          if (zone.contenu.type_uid && zone.contenu.type_uid === 'TEXTELIBRE') {
            $content.style.textTransform = 'inherit'
          }

          let { width: contentWidth, height: contentHeight } = $content.getBoundingClientRect()
          const { width: containerWidth, height: containerHeight } = $container.getBoundingClientRect()
          // Ajustement de la taille de la police pour le front-end
          while ((contentWidth < containerWidth && contentHeight < containerHeight) && minFontSize < maxFontSize) {
            minFontSize += step
            $content.style.fontSize = `${minFontSize}px`
            contentWidth = $content.getBoundingClientRect().width
            contentHeight = $content.getBoundingClientRect().height
          }

          // Une fois que la taille dépasse, revenir au dernier step où c'était correct
          minFontSize -= step
        }
      }

      // Définir les tailles de police basées sur minFontSize
      const fontSizeMap = {
        1: minFontSize * 0.3,
        2: minFontSize * 0.4,
        3: minFontSize * 0.5,
        4: minFontSize * 0.7,
        5: minFontSize * 0.8,
        6: minFontSize * 0.9, // Taille maximale
        7: minFontSize * 1.0, // Même taille maximale pour 6 et 7
      }

      // Ajuster la taille de police pour la clé de zone
      baseFontSizeValue = Math.min(Math.max(baseFontSizeValue, 1), 7)
      const selectedFontSize = this.helperService.round(fontSizeMap[baseFontSizeValue], 0)
      if ($content) {
        $content.style.fontSize = `${selectedFontSize}px`
      }

      if ($container) {
        return { frontFontSize: selectedFontSize, baseFontSizeValue }
      }

      return false
    },

    async getData() {
      this.emitter.emit('open-loader')
      const mrq = await this.fetchService.get(`etiquette/modele/${this.id}`)
      const mr = await mrq.data
      this.data.modele = mr
      this.data.zones = []

      const zrq = await this.fetchService.get(`etiquette/modele/${this.id}/zone`)
      const zones = await zrq.data
      const { L, H } = this.getRealSizeLabel(this.data.modele.gabarit)
      // eslint-disable-next-line prefer-spread
      const l = 100// Math.max(...gz.map((o) => parseInt(o.x, 10) + parseInt(o.largeur, 10)))
      const h = 100// Math.max(...gz.map((o) => parseInt(o.y, 10) + parseInt(o.hauteur, 10)))
      this.data.etiquette = {
        L, H, l, h,
      }
      zones.forEach((item, index) => {
        this.data.zones[index] = {}
        this.data.zones[index].gabarit = Object.fromEntries(
          Object.entries(this.data.modele.gabarit).filter(([key, value]) => key !== 'zones' && value),
        )
        this.data.zones[index].zone_format = item.gabarit_zone
        if (+this.data.zones[index].zone_format.x === 0) {
          this.data.zones[index].zone_format.x = 1
        } else {
          this.data.zones[index].zone_format.x = +this.data.zones[index].zone_format.x + 1
        }
        if (+this.data.zones[index].zone_format.y === 0) {
          this.data.zones[index].zone_format.y = 1
        } else {
          this.data.zones[index].zone_format.y = +this.data.zones[index].zone_format.y + 1
        }
        this.data.zones[index].zone_format.hauteur = +this.data.zones[index].zone_format.hauteur
        this.data.zones[index].zone_format.largeur = +this.data.zones[index].zone_format.largeur
        this.data.zones[index].contenu = {
          id: item.id,
          couleur: item.couleur,
          gras: item.gras,
          italique: item.italique,
          facteur_taille_texte: item.facteur_taille_texte,
          type_uid: item.type_valeur_etiquette?.uid,
          type_valeur_etiquette: item.label ?? item.type_valeur_etiquette?.placeholder ?? item.type_valeur_etiquette?.designation,
        }
      })
      this.data.zones = this.sortByOrder(this.data.zones)

      this.emitter.emit('close-loader')
    },

    sortByOrder(element) {
      const data = {}
      const keys = Object.keys(element).sort(
        (a, b) => element[a].zone_format.id - element[b].zone_format.id,
      )
      keys.forEach((k, i) => {
        if (Number.isNaN(+k)) {
          data[k] = element[k]
        } else {
          data[i] = element[k]
        }
      })
      return data
    },
    getRealSizeLabel(gabarit) {
      let L = (Number(+gabarit.page_largeur) - Number(+gabarit.marge_gauche)
        - Number(+gabarit.marge_droite)
        - ((Number(+gabarit.nb_etiquettes_x) - 1) * Number(+gabarit.marge_etiquette_x)))
        / Number(+gabarit.nb_etiquettes_x)

      let H = (Number(+gabarit.page_hauteur) - Number(+gabarit.marge_haut)
        - Number(+gabarit.marge_bas)
        - ((Number(+gabarit.nb_etiquettes_y) - 1) * Number(+gabarit.marge_etiquette_y)))
        / Number(+gabarit.nb_etiquettes_y)

      L = Number(L.toFixed(2))
      H = Number(H.toFixed(2))

      return { L, H }
    },
  },

}
</script>
<style lang="scss" scoped>
.modele-grid {
  box-shadow: inset 0 0 0 1px;
  font-family: sans-serif;
  background-color: white;
  margin: 0 auto;
  width: 95%;
  aspect-ratio: v-bind(ratio);
  display: grid;
  grid-template-columns: repeat(v-bind(le), 1fr);
  grid-template-rows: repeat(v-bind(he), 1fr);
  grid-gap: 1px;
  padding: 1px;
}

.modele-grid__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  >p {
    text-align: center;
    @include h-padding(5px);
    margin: 0;
  }
}

.modele-grid__index {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: black;
  font-size: $font-size-small;
  font-weight: bold;
  color: white;
  @include size(2rem);
  line-height: 2rem;
  border-radius: 50%;
  text-align: center;
}
</style>
