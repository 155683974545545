<template>
  <form @submit.prevent="onSubmit()">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
        <Btn
          v-if="helperService.userHasPermission('entite_add')"
          class="action-btn"
          icon="plus"
          color="primary"
          text="Créer une entité"
          :to="{
            name: 'entityAdd',
            params: {
              action: 'add',
            },
          }"
        />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>
    <div class="view-body">
      <Section class="section section--list">
        <Search
          v-model="search"
          :searchEndpoint="list.api.endpoint"
          searchPlaceholder="Rechercher une entité"
        >
          <template v-slot:suggestions="{ item }">
            <router-link :to="{ name: 'entity', params: { id: item.id } }">
              {{ item.nom }}
            </router-link>
          </template>

          <SelectExtended
            id="entite"
            v-model="search.filters.type.values"
            multiple
            search
            label="Type d'entité"
            optionKey="id"
            optionValue="designation"
            apiEndpoint="dictionnaire/type/entite"
          />

          <SelectExtended
            id="profil"
            v-model="search.filters.parent.values"
            multiple
            label="Entité parente"
            optionKey="id"
            optionValue="nom"
            apiEndpoint="entite"
          />
        </Search>

        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
          disableCheckbox
        >
          <template v-slot:parent="{ item }">
            {{ item.parent?.nom }}
          </template>

          <template v-slot:type="{ item }">
            {{ item.type.designation }}
          </template>

          <template v-slot:actions="{ item }">
            <Btn
              v-if="helperService.userHasPermission('entite_show')"
              icon="visibility"
              default
              round
              grow
              :to="{
                name: 'entity',
                params: {
                  id: item.id,
                },
              }"
            />

            <Btn
              v-if="helperService.userHasPermission('entite_edit')"
              icon="create"
              default
              round
              grow
              :to="{
                name: 'entityEdit',
                params: {
                  id: item.id,
                  action: 'edit',
                },
              }"
            />
          </template>
        </List>
      </Section>
    </div>
  </form>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Search from '@/components/list/Search.vue'

export default {
  name: 'EntitiesView',

  components: {
    Search,
    SelectExtended,
    Pagination,
    List,
    Btn,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      search: {
        attributs: ['nom'],
        filters: {
          type: {
            type: 'object',
            key: 'id',
            values: [],
          },
          parent: {
            type: 'object',
            values: [],
          },
        },
      },

      list: {
        api: {
          endpoint: 'entite',
          params: {
            sort: 'nom.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Designation', dataField: 'nom' },
          { label: "Type d'entité" },
          { label: 'Entité parente', hidden: 'mobile' },
        ],
        col: ['nom', 'type', 'parent'],
        key: 'id',
      },
      entity: [],
      loading: false,
    }
  },

  created() {
    this.getEntity()
  },

  methods: {
    async getEntity() {
      this.loading = true

      await this.fetchService
        .get(`entite/${this.$store.state.auth.user.data.entite.id}`)
        .then((response) => {
          this.entity = response.data

          this.loading = false
        })
    },
  },
}
</script>

<style scoped>
</style>
