<template>
  <div class="view-header" v-if="pageTitle">
    <div class="view-header__footer">
      <Btn
        v-if="$route.params.id"
        class="back-btn"
        round
        grow
        icon="arrow-left"
        :to="{
            name: 'entities'
          }"
      />
      <h1 class="page-title" v-html="pageTitle"></h1>
    </div>
  </div>

  <div class="view-body">
    <Section>
      <Container>
        <div class="grid" v-if="!loading">
          <div class="tmp-col">
            <KeyValue label="Nom" :value="entity.nom" />
            <KeyValue label="Type d'entité" :value="entity.type.designation" />
            <KeyValue label="Entité parente" :value="entity.parent?.nom" />
            <KeyValue label="Adresse" :value="entity.adresse" />
            <KeyValue label="Code postal" :value="entity.cp" />
            <KeyValue label="Ville" :value="entity.ville" />
            <KeyValue label="Pays" :value="entity.pays?.valeur" />
            <KeyValue label="Description" :value="entity.description" />
          </div>
          <div class="tmp-col">
            <KeyValue label="Logo primaire" type="file" :value="entity.logo_prim ?? null" />

            <KeyValue label="Logo secondaire" type="file" :value="entity.logo_sec ?? null" />

            <KeyValue label="Logo secondaire 2" type="file" :value="entity.logo_ter ?? null" />
          </div>
        </div>
      </Container>
    </Section>
  </div>

  <div class="view-footer">
    <div class="view-actions">
      <Btn
        v-if="helperService.userHasPermission('entite_edit')"
        class="action-btn"
        icon="create"
        color="primary"
        text="Modifier l'entité"
        :to="{
        name: $route.params.id ? 'entityEdit' : 'myEntityEdit',
        params: {
          ...($route.params.id !== undefined ?? { id: entity.id }),
          action: 'edit'
        }
      }"
      />
    </div>
  </div>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'EntityView',

  components: {
    Btn,
    KeyValue,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      entity: [],
      logo_primary: '',
      logo_secondary: '',
      logo_terciary: '',
      entityId: this.$route.params.id ?? this.$store.state.auth.user.data.entite.id,
      loading: false,
    }
  },

  created() {
    this.getEntity()
  },

  methods: {
    async getEntity() {
      this.loading = true

      await this.fetchService.get(`entite/${this.entityId}`)
        .then((response) => {
          this.entity = response.data

          this.displayMedia(this.entity.logo_prim, this.entity.logo_sec, this.entity.logo_ter)

          this.loading = false
        })
    },

    displayMedia(...medias) {
      medias.filter((media) => media !== null).forEach((item) => {
        this.fileService.displayImage(item.jeton).then((response) => {
          switch (medias.indexOf(item)) {
            case 0:
            default:
              this.logo_primary = response.data
              break
            case 1:
              this.logo_secondary = response.data
              break
            case 2:
              this.logo_terciary = response.data
              break
          }
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
