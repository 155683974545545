<template>
  <div class="view view--references">

    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <Btn class="back-btn" round grow icon="arrow-left" :to="{ name: 'logs' }" />
        <h1 class="page-title">
          {{ pageTitle }} - {{ log.objet }} - identifiant: {{ log.id }}
        </h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>

          <!-- TODO: Nico -->
          <div v-highlight>
            <pre class="language-json"><code>{{ log }}</code></pre>
          </div>

        </Container>
      </Section>
    </div>

  </div>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import 'prism-es6/components/prism-markup-templating'
import 'prism-es6/components/prism-json'

export default {
  name: 'LogView',

  components: {
    Btn,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      log: [],
    }
  },

  mounted() {
    this.getLog()
  },

  methods: {
    getLog() {
      this.fetchService.get(`log/${this.$route.params.id}`).then((response) => {
        this.log = response.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
