<template>
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
        <Btn
          v-if="helperService.userHasPermission('dictionariesetiquettegabarit_add')"
          class="action-btn"
          icon="plus"
          color="primary"
          text="Ajouter un gabarit d'étiquette"
          :to="{
            name: 'templateLabelAdd',
          }"
        />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>
    <div class="view-body">
      <Section class="section section--list">
        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
          :target="list.target"
        >
          <template v-slot:group-actions="{ selectedItems }">
            <Btn
            v-if="helperService.userHasPermission('dictionariesetiquettegabarit_remove')"
              text="Supprimer"
              icon="trash-alt"
              color="default"
              @click="openModal('deleteItems', selectedItems)"
            />
          </template>

          <template v-slot:actions="{ item }">
            <ToolDropdown>
              <MenuItem
              v-if="helperService.userHasPermission('dictionariesetiquettegabarit_show')"
                :item-data="{
                  label: 'Consulter',
                  iconName: 'visibility',
                  route: {
                    name: 'templateLabel',
                     params: {
                      id: item.id,
                     },
                    }
                 }"
              />
              <MenuItem
                v-if="helperService.userHasPermission('dictionariesetiquettegabarit_edit')"
                :item-data="{
                  label: 'Éditer/supprimer',
                  iconName: 'create',
                  route: {
                    name: 'templateLabelEdit',
                     params: {
                      id: item.id,
                     },
                    }
                 }"
              />
            </ToolDropdown>

          </template>
        </List>
      </Section>
    </div>

  <!-- Modals -->
  <!-- Groupe d'actions de suppression -->
  <Modal
    title="Supprimer des gabarits&nbsp;?"
    :active="modal.deleteItems"
    :data="modalData"
    @modal-close="modal.deleteItems = false"
  >
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer
        {{ data.length }} gabarits&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItems = false" />
      <Btn
        text="Supprimer"
        @click="deleteItems(data)"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'

export default {
  name: 'TemplatesLabelView',

  components: {
    MenuItem,
    ToolDropdown,
    Modal,
    Pagination,
    List,
    Btn,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      list: {
        target: {
          route: {
            name: 'templateLabel',
            paramsFromRoute: undefined,
            itemParams: { id: 'id' },
          },
        },
        api: {
          endpoint: 'dictionnaire/etiquette/gabarit',
          params: {
            sort: 'designation.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Désignation', dataField: 'designation', isLink: true },
        ],
        col: ['designation'],
        key: 'id',
      },

      modalData: {},
      modal: {
        deleteItems: false,
        deleteGabarit: false,
      },
    }
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        this.fetchService.delete(`dictionnaire/etiquette/gabarit/${item.id}`).then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: `Le gabarit ${item.designation} a été supprimé.`,
            })
            this.emitter.emit('list-refresh')
            this.emitter.emit('close-loader')
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: 'data' in responseError ? responseError.data : responseError.message,
            })
            this.emitter.emit('close-loader')
          },
        )
      })

      this.modal.deleteItems = false
    },
  },

}
</script>

<style scoped>
</style>
