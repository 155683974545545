<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--styleguide">
      <div class="view-header" v-if="pageTitle">
        <div class="view-header__footer">
          <Btn
            class="back-btn"
            round
            grow
            icon="arrow-left"
            :to="{ name: 'referential', params }"
          />
          <h1 class="page-title">
            {{ title }}
          </h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>
            <div class="grid">
              <div class="tmp-col">
                <Input id="valeur" label="Désignation" />
              </div>
            </div>
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <Btn text="Annuler" :to="{ name: 'referential', params }" />
        <Btn btnType="submit" text="Enregistrer" color="primary" />
      </div>
    </div>
  </form>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'ReferentialItemAddView',

  components: {
    Btn,
    Input,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      valeur: this.yup.string().required(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleItem(values, this.action)
    })

    return {
      type: null,
      id: null,
      endpoint: null,
      dataEndpoint: null,
      title: null,
      method: null,
      data: {},
      params: {},
      onSubmit,
      errors,
      isSubmitting,
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  created() {
    this.type = this.$route.params.type
    this.id = this.$route.params.id

    switch (this.type) {
      case 'referentiel':
      default:
        this.getReferentialName()
        this.endpoint = `dictionnaire/referentiel/${this.$route.params.id}/valeur`
        this.params = {
          type: this.type,
          id: this.id,
        }
        break
      case 'unites':
        if (this.$route.params.id_value) {
          this.title = 'Unités - modifier une valeur'
        } else {
          this.title = 'Unités - ajouter une valeur'
        }
        this.endpoint = 'unite'
        this.params = {
          type: this.type,
        }
        break
    }

    this.method = 'post'
    if (this.$route.params.id_value) {
      this.endpoint = `${this.endpoint}/${this.$route.params.id_value}`
      this.method = 'put'
      this.getData()
    }
  },

  methods: {
    getData() {
      // get data from API
      this.emitter.emit('open-loader')
      this.fetchService.get(this.endpoint).then(
        (response) => {
          this.data = response.data
          // Populate schema with data
          this.schema = this.formService.populateShema(this.data)
          // set form values
          this.formService.setFormValues(this.schema)
          this.emitter.emit('close-loader')
        },
        () => {
          this.emitter.emit('close-loader')
        },
      )
    },
    getReferentialName() {
      this.fetchService
        .get(`dictionnaire/referentiel/${this.$route.params.id}`)
        .then((response) => {
          if (this.$route.params.id_value) {
            this.title = `${response.data.designation} - modifier une valeur`
          } else {
            this.title = `${response.data.designation} - ajouter une valeur`
          }
        })
    },
    handleItem(values) {
      this.emitter.emit('open-loader')
      this.fetchService[this.method](this.endpoint, {
        uid: this.helperService.generateUid(values.valeur),
        valeur: values.valeur,
      }).then(
        () => {
          this.formService.resetForm()
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La valeur a bien été ajoutée.',
          })
          this.emitter.emit('close-loader')
          const { params } = this
          this.$router.push({ name: 'referential', params })
        },
        (responseError) => {
          this.formService.handleApiError(responseError)
          this.emitter.emit('close-loader')
        },
      )
    },
  },
}
</script>

<style scoped>
</style>
