<template>
  <!-- Permissions listing -->
  <template v-if="!loading">
    <List class="tabs-content" :listData="listData" :header="list.headers" :items="list.col" :item-key="list.key"
      :disableGroupActions="true" :disableActions="true" :disableGlobalCheckbox="true"
      :default-selected-items="permissionsSelection" @selected-items="updateSelected">
    </List>
  </template>

</template>

<script>
import List from '@/components/list/List.vue'

export default {
  name: 'RoleProfileListingSubview',

  components: {
    List,
  },

  props: {
    modelValue: {
      type: [Array, Boolean],
      default: undefined,
    },
  },

  data() {
    return {
      list: {
        api: {
          params: {
            sort: 'uid',
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [{ label: 'Designation', grow: true }],
        col: ['designation'],
        key: 'uid',
      },
      listData: [],

      categoryId: this.$route.params.categoryId,

      // Permissions selected
      permissionsSelection: [],
      defaultSelectedItems: [],

      // If update
      profilePermissions: [],
      loading: true,

      items: [],
      componentKey: 0,
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$route.params.categoryId': function () {
      console.log('watch')
      // we display the permissions with the ID that is passed as a parameter of the route
      if (this.$route.params.categoryId) {
        this.parsePermissionsArray()
      }
    },
  },

  created() {
    this.emitter.emit('open-loader')
    this.fetchService.get(`profil/${this.$route.params.id}/droit`).then(
      (response) => {
        this.profilePermissions = response.data.map((permission) => {
          // eslint-disable-next-line  no-param-reassign
          delete permission.pivot
          return permission
        })
        this.permissionsSelection = response.data
        this.parsePermissionsArray()
        this.$store.dispatch('roleProfile/selection', this.permissionsSelection)
      },
      (error) => {
        console.log(error)
      },
    )
  },

  methods: {
    parsePermissionsArray() {
      this.emitter.emit('open-loader')
      this.loading = true
      let localListData = {}
      this.modelValue.forEach((item) => {
        if (item.id === parseInt(this.$route.params.categoryId, 10)) {
          localListData = item
        } else {
          item.enfants.forEach((child) => {
            if (child.id === parseInt(this.$route.params.categoryId, 10)) {
              localListData = child
            }
          })
        }
      })

      this.listData = localListData.droits

      setTimeout(() => {
        this.loading = false
        this.emitter.emit('close-loader')
      }, '300')
    },

    updateSelected(event) {
      const handleEvent = Object.values('selected' in event ? event.selected : event)
      if (handleEvent.length > 0) {
        this.$store.dispatch('roleProfile/selection', handleEvent)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.list-row--head) {
  background-color: $color-primary;
  color: white;

  &:hover {
    background-color: $color-primary;
  }

  .checkbox {
    label {
      &::before {
        border-color: white;
      }
    }

    :checked+label {
      &::before {
        background-color: white;
      }

      &::after {
        border-color: $color-primary;
      }
    }
  }
}
</style>
