<template>
  <div class="view">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
        <Btn
          v-if="helperService.userHasPermission('profil_add')"
          class="action-btn"
          icon="create"
          color="primary"
          text="Ajouter un profil"
          :to="{ name: 'roleProfileAddEdit', params: { action: 'add' } }"
        />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section section--list">
        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
          :target="list.target"
        >
          <template v-slot:group-actions="{ selectedItems }">
            <Btn
              v-if="helperService.userHasPermission('profil_remove')"
              text="Supprimer"
              color="default"
              @click="openModal('deleteItems', selectedItems)"
            />
          </template>

          <template v-slot:entite_types="{ item }">
              <div class="tags" v-if="entityTypes[item.id] && entityTypes[item.id].length > 0">
                <MiniTag
                  v-for="profil in entityTypes[item.id]"
                  :key="profil.id"
                  :id="profil.uid"
                  :text="profil.designation"
                />
              </div>
            <div v-else>—</div>
          </template>

          <template v-slot:actions="{ item }">
            <Btn
              v-if="helperService.userHasPermission('profil_add,profil_edit')"
              icon="visibility"
              :to="{ name: 'roleProfile', params: { id: item.id } }"
              round
              grow
              default
            />
            <Btn
              v-if="
              !(item.uid === 'super-administrateur') &&
              helperService.userHasPermission('profil_edit')"
              :to="{
                name: 'roleProfileAddEdit',
                params: { id: item.id, action: 'edit' },
              }"
              icon="create"
              round
              grow
              default
            />
          </template>
        </List>
      </Section>
      <Modal
        title="Supprimer des profils&nbsp;?"
        :active="modal.deleteItems"
        :data="modalData"
        @modal-close="modal.deleteItems = false"
      >
        <template v-slot:modal-body="{ data }">
          <p>
            Voulez vous vraiment supprimer
            {{ data.length }} profils&nbsp;?
          </p>
        </template>
        <template v-slot:modal-footer="{ data }">
          <Btn text="Annuler" @click="modal.deleteItems = false" />
          <Btn
            text="Supprimer"
            @click="deleteItems(data)"
            icon="trash-alt"
            color="primary"
          />
        </template>
      </Modal>

    </div>
  </div>
</template>

<script>
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Modal from '@/components/layout/Modal.vue'
import MiniTag from '@/components/base/MiniTag.vue'

export default {
  name: 'RoleProfilesView',

  components: {
    MiniTag,
    List,
    Pagination,
    Btn,
    Section,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      list: {
        target: {
          route: {
            name: 'roleProfile',
            paramsFromRoute: undefined,
            itemParams: { id: 'id' },
          },
        },
        api: {
          endpoint: 'profil',
          params: {
            sort: 'designation.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Désignation', dataField: 'designation', isLink: true },
          { label: "Niveau d'entité requis", hidden: 'mobile' },
        ],
        col: ['designation', 'entite_types'],
        key: 'id',
      },
      entityTypes: [],
      data: [],
      modalData: {},
      modal: {
        deleteItems: false,
      },
    }
  },

  mounted() {
    this.getData()
    this.$store.dispatch(
      'roleProfile/selection',
      [],
    )
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      const it = Object.values(itemsToDelete)

      await it.forEach((item) => {
        if (item.uid !== 'super-administrateur') {
          this.fetchService.delete(`profil/${item.id}`).then(
            () => {
              this.emitter.emit('alert', {
                type: 'success',
                content: `Le profil ${item.designation} a bien été supprimé.`,
              })
            },
            (responseError) => {
              this.emitter.emit('alert', {
                type: 'error',
                content: responseError.message,
              })
            },
          )
        }
      })

      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
      this.modal.deleteItems = false
    },

    getData() {
      this.fetchService.get('profil').then((response) => {
        this.data = response.data

        this.getEntityTypeForProfil()
      })
    },

    getEntityTypeForProfil() {
      this.data.forEach((profil) => {
        this.fetchService.get(`profil/${profil.id}/entite_type`).then((response) => {
          this.entityTypes[[profil.id]] = response.data
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.role-profile {
  padding: $gutter-half;
}

.list-row {
  border: 1px solid grey;
}
</style>
