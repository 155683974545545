<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--culture--add">
      <div class="view-header" v-if="pageTitle">
        <div class="view-header__footer">
          <Btn
            class="back-btn"
            round
            grow
            icon="arrow-left"
            :to="{ name: 'referential', params }"
          />
          <h1 class="page-title">
            {{ title }}
          </h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>
            <div class="grid">
              <div class="tmp-col">
                <Input id="uid" label="UID"/>
                <Input id="nom" label="Nom"/>
              </div>
              <div class="tmp-col">
                <InputImage
                  id="media_id"
                  :media="data.media"
                  type="picto"
                  label="Désignation"
                  textInfo="Fichier au format png,svg,jpg 10Mo maximum"
                  @update:modelValue="setMedia($event)"
                  required
                />
              </div>
            </div>
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <Btn text="Annuler" :to="{ name: 'referential', params }" />
        <Btn btnType="submit" text="Enregistrer" color="primary" />
      </div>
    </div>
  </form>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import InputImage from '@/components/form/InputImage.vue'
import Input from '@/components/form/Input.vue'

export default {
  name: 'PictogramAddEditView',

  components: {
    Input,
    InputImage,
    Btn,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      uid: this.yup.string().required(),
      nom: this.yup.string().required(),
      media_id: this.yup.object().required().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleItem(values, this.action)
    })

    return {
      type: null,
      id: null,
      endpoint: null,
      title: null,
      method: null,
      data: {},
      params: {},
      onSubmit,
      errors,
      isSubmitting,
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  created() {
    this.type = this.$route.params.type
    this.id = this.$route.params.id

    this.title = `Pictogramme - ${this.id === undefined ? 'Ajouter' : 'Modifier'} une valeur`

    this.endpoint = 'pictogramme'
    this.params = {
      type: this.type,
    }

    this.method = 'post'
    if (this.id !== undefined) {
      this.endpoint = `${this.endpoint}/${this.id}`
      this.method = 'put'
      this.getData()
    }
  },

  methods: {
    setMedia(event) {
      this.media_id = event
    },
    getData() {
      // get data from API
      this.emitter.emit('open-loader')
      this.fetchService.get(this.endpoint).then(
        (response) => {
          this.data = response.data
          // Populate schema with data
          this.schema = this.formService.populateShema(this.data)

          this.schema.media_id = this.data.media

          // set form values
          this.formService.setFormValues(this.schema)
          this.emitter.emit('close-loader')
        },
        () => {
          this.emitter.emit('close-loader')
        },
      )
    },

    handleItem(values) {
      // eslint-disable-next-line no-param-reassign
      values.media_id = values.media_id.id
      // eslint-disable-next-line no-param-reassign
      values.uid = this.helperService.generateUid(values.uid)
      this.fetchService[this.method](this.endpoint, values).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le pictogramme a bien été ajouté.',
          })
          const { params } = this
          this.$router.push({ name: 'referential', params })
        },
        (responseError) => {
          let content = ''
          if ('errors' in responseError) {
            content = responseError.errors
            this.formService.setFieldError('valeur', ' ')
          } else {
            content = `${responseError}`
          }
          this.emitter.emit('alert', {
            type: 'error',
            content,
          })
        },
      )
    },
  },
}
</script>

<style scoped>
</style>
