<template>
  <div class="tabs-material">
    <!-- {{ tabs }} -->
    <ul>
      <li v-for="(tab, i) in tabs" :key="i">
        <router-link :to="{ name: tab.urlName, params: tab.params }"
          @click="scrollToActive()"
        >
          <span>{{ tab.text }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TabsMaterial',

  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    this.scrollToActive()
  },

  methods: {
    scrollToActive() {
      window.setTimeout(() => {
        const $tabs = document.querySelector('.tabs-material')
        const $activeTab = document.querySelector('.tabs-material .router-link-exact-active')

        if ($tabs && $activeTab) {
          $tabs.scrollTo({
            top: 0,
            left: ($activeTab.offsetLeft - ($tabs.offsetWidth - $activeTab.offsetWidth) / 2),
            behavior: 'smooth',
          })
        }

        // console.log('Tabs visible width', $tabs.offsetWidth)
        // console.log('Tabs total width', $tabs.scrollWidth)
        // console.log('Active tab width', $activeTab.offsetWidth)
        // console.log('Active tab position', $activeTab.offsetLeft)
      }, 100)
    },
  },
}
</script>

<style lang="scss" scoped>
// TABS MATERIAL

.tabs-material {
  overflow-x: auto;
  position: relative;
  background-color: $color-primary;
  // @include shadow(2);
  box-shadow: 0 8px 4px 0 rgba(0,0,0,0.065), 0 4px 2px 0 rgba(0,0,0,0.125);
  margin-top: -1px; // Hide view-header separator line

  ul {
    @include reset-list();
    display: flex;
    align-items: flex-end;
  }

  li {
    flex-shrink: 0;
    max-width: 17rem;
  }

  a {
    color: white;
    display: block;
    padding: $gutter-half $gutter;
    opacity: 0.6;
    font-size: $font-size-big/*ger*/;
    font-weight: 500;
    line-height: $line-height-small;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 0.5rem;
      bottom: 0;
      left: 0;
      background-color: currentColor;
      transform-origin: 50% 100%;
      opacity: 0;
      transform: scaleY(0);
      transition: 0.25s
    }

    &.router-link-exact-active {
      opacity: 1;
      position: relative;

      &::after {
        opacity: 1;
        transform: scaleY(1);
      }
    }
  }
}
</style>
