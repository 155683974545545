<template>
  <div class="view-header" v-if="pageTitle">
    <div class="view-header__header">
      <Pagination />
      <Btn
        v-if="helperService.userHasPermission('etiquettemodele_add')"
        class="action-btn"
        icon="plus"
        color="primary"
        text="Ajouter un modèle d'étiquette"
        :to="{
            name: 'modelLabelAdd',
          }"
      />
    </div>
    <div class="view-header__footer">
      <h1 class="page-title" v-html="pageTitle"></h1>
    </div>
  </div>

  <div class="view-body">
    <Section class="section section--list">
      <List
        :api="list.api"
        :header="list.headers"
        :items="list.col"
        :item-key="list.key"
        :target="list.target"
      >
        <template v-slot:group-actions="{ selectedItems }">
          <Btn
            v-if="helperService.userHasPermission('etiquettemodele_remove')"
            text="Supprimer"
            icon="trash-alt"
            color="default"
            @click="openModal('deleteItems', selectedItems)"
          />
        </template>

        <template v-slot:actions="{ item }">
          <ToolDropdown>
            <MenuItem
              v-if="helperService.userHasPermission('etiquettemodele_add,etiquettemodele_edit')"
              :item-data="{
                  label: 'Consulter',
                  iconName: 'visibility',
                  route: {
                    name: 'modelLabel',
                     params: {
                      id: item.id,
                     },
                    }
                 }"
            />
            <MenuItem
              v-if="helperService.userHasPermission('etiquettemodele_edit')"
              :item-data="{
                  label: 'Éditer/supprimer',
                  iconName: 'create',
                  route: {
                    name: 'modelLabelEdit',
                     params: {
                      id: item.id,
                     },
                    }
                 }"
            />
          </ToolDropdown>

        </template>
      </List>
    </Section>
  </div>

  <!-- Modals -->
  <!-- Groupe d'actions de suppression -->
  <Modal
    title="Supprimer des modèles d'étiquettes&nbsp;?"
    :active="modal.deleteItems"
    :data="modalData"
    @modal-close="modal.deleteItems = false"
  >
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer
        {{ data.length }} modèles d'étiquettes&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItems = false" />
      <Btn
        text="Supprimer"
        @click="deleteItems(data)"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'

export default {
  name: 'ModelsLabelView',

  components: {
    MenuItem,
    ToolDropdown,
    Modal,
    Pagination,
    List,
    Btn,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      list: {
        target: {
          route: {
            name: 'modelLabel',
            paramsFromRoute: undefined,
            itemParams: { id: 'id' },
          },
        },
        api: {
          endpoint: 'etiquette/modele',
          params: {
            sort: 'nom.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Nom', dataField: 'nom', isLink: true },
        ],
        col: ['nom'],
        key: 'id',
      },

      modalData: {},
      modal: {
        deleteItems: false,
        deleteGabarit: false,
      },
    }
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        this.fetchService.delete(`etiquette/modele/${item.id}`).then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: `Le modèle d'étiquette ${item.nom} a été supprimé.`,
            })
            this.emitter.emit('list-refresh')
            this.emitter.emit('close-loader')
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: 'data' in responseError ? responseError.data : responseError.message,
            })
            this.emitter.emit('close-loader')
          },
        )
      })

      this.modal.deleteItems = false
    },
  },

}
</script>

<style scoped>
</style>
