<template>
  <div class="view view-role-profile-add-edit">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <Btn class="back-btn" round grow icon="arrow-left" :to="{ name: 'roleProfiles' }" />
        <h1 class="page-title">
          {{ title }}
        </h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section">
        <Container>
          <div class="grid">
            <div class="tmp-col">
              <Input v-model="form.designation" label="Désignation" id="designation" placeholder="Désignation"
                required />
            </div>
            <div class="tmp-col grid-col">
              <SelectExtended v-if="displaySelect && helperService.userHasPermission('profiltypeentite_edit')"
                v-model="form.entitySelection" multiple chips option-key="uid" option-value="designation"
                label="Niveau d'entité requis" text-info="Plusieurs choix possible" :items="$store.state.app.typeEntite"
                required />
            </div>
          </div>

          <div class="tabs tabs--sidebar">
            <div class="tabs-togglers">
              <h2 class="h3">Groupes de droits</h2>
              <template v-for="permission in permissions" :key="permission.id">
                <Btn class="tab-toggler" :to="{
      name: `roleProfileListing`, params: {
        ...(action === 'edit' && { id: $route.params.id }),
        title: permission.designation,
        categoryId: permission.id
      },
    }">
                  {{ permission.designation }}
                </Btn>
                <template v-for="enfant in permission.enfants" :key="enfant.id">
                  <Btn class="tab-toggler tab-toggler--child" :to="{
      name: `roleProfileListing`, params: {
        ...(action === 'edit' && { id: $route.params.id }),
        title: enfant.designation,
        categoryId: enfant.id
      },
    }">
                    {{ enfant.designation }}
                  </Btn>
                </template>
              </template>
            </div>

            <div v-if="!loading">
              <h2 class="h3">Droits du profil</h2>
              <router-view v-model="permissions" ></router-view>
            </div>
          </div>
        </Container>
      </Section>
    </div>

    <div v-if="!(profile.uid === 'super-administrateur')" class="view-footer">
      <div class="view-actions view-actions--left">
        <Btn text="Annuler" :to="{ name: 'roleProfiles' }" />
        <Btn v-if="action === 'edit'" text="Supprimer" hollow @click="modalActive = true" />
        <Btn btnType="submit" color="primary" :text="action === 'add' ? 'Enregistrer' : 'Modifier'"
          @click="action === 'add' ? createProfile() : updateProfile()">
        </Btn>
      </div>
    </div>

    <!-- Modals -->
    <Modal title="Supprimer le profil de droit&nbsp;?" :active="modalActive" :data="profile"
      @modal-close="modalActive = false">
      <template v-slot:modal-body="{ data }">
        <p>
          Voulez vous vraiment supprimer le profil de droit
          <b>{{ data.designation }}</b>?
        </p>
      </template>
      <template v-slot:modal-footer>
        <Btn text="Annuler" @click="modalActive = false" />
        <Btn text="Supprimer" @click="deleteProfile()" icon="trash-alt" color="primary" />
      </template>
    </Modal>

  </div>
</template>

<script>
import Input from '@/components/form/Input.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'RoleProfileAddEditView',

  components: {
    Modal,
    Btn,
    Section,
    Container,
    SelectExtended,
    Input,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      /*
      API
      */
      api: {
        endpoint: 'dictionnaire/type/entite',
      },
      profile: [],

      /*
      LISTINGS
      */
      // Permissions categories
      permissions: {},
      // Permission title
      permissionTitle: '',
      // Loading
      loading: false,
      /*
      FORM
       */
      form: {
        // Designation
        designation: '',
        // Entity selection
        entitySelection: [],
      },
      /*
      DATA POPULATE
       */
      data: [],
      modalActive: false,
      action: 'add',
    }
  },

  computed: {
    title() {
      switch (this.action) {
        case 'add':
          return 'Ajouter un profil de droit'
        case 'edit':
          return `Modifier le profil de droit - ${this.profile.designation}`
        default:
          return 'Ajouter un profil de droit'
      }
    },
    displaySelect() {
      if (this.action === 'add') {
        return true
      }

      return this.action === 'edit' && !this.loading
    },
  },

  created() {
    if (this.$route.params.id) {
      this.action = 'edit'
      this.getProfile()
    }
    this.getPermissions()
  },

  methods: {
    /**
     * Profile data
     */
    getProfile() {
      this.loading = true
      this.fetchService.get(`profil/${this.$route.params.id}`).then(
        (response) => {
          this.profile = response.data
          this.form.designation = this.profile.designation

          this.fetchService.get(`profil/${this.profile.id}/entite_type`).then((responseType) => {
            this.form.entitySelection = responseType.data.map(
              (entity) => ({ key: entity.uid, value: entity.designation }),
            )

            this.loading = false
          })
        },
        (error) => {
          console.log(error)
          this.loading = false
        },
      )
    },
    /**
     * Permissions categories
     */
    getPermissions() {
      this.fetchService.get('dictionnaire/categorie/droit', { limit: 0, sort: 'uid' }).then(
        (response) => {
          this.permissions = response.data
          this.permissionTitle = this.permissions[0].designation

          this.$router.push(
            {
              name: 'roleProfileListing',
              params: {
                ...(this.action === 'edit' && { id: this.$route.params.id }),
                title: this.permissionTitle,
                categoryId: 1,
              },
            },
          )
        },
        (error) => {
          console.log(error)
        },
      )
    },

    /**
     * Create the profile
     */
    async createProfile() {
      if (this.form.entitySelection.length === 0) {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'Le champ Niveau d\'entité requis est obligatoire.',
        })
      } else {
        const response = await this.fetchService.post('profil', {
          uid: this.helperService.generateUid(this.form.designation),
          designation: this.form.designation,
        })

        const meta = await response.meta
        const data = await response.data

        if (meta.success && data) {
          const profileId = response.data.id
          const profUpdate = await this.updateProfileEntityTypes(profileId)
          const permUpdate = await this.updateProfilePermissions(profileId)

          if (profUpdate && permUpdate) {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Le profil a bien été créé.',
            })

            this.$router.push({
              name: 'roleProfile',
              params: {
                id: profileId,
              },
            })
          }
        } else {
          let error = 'La valeur du champ désignation est déjà utilisé.'
          if (data?.fields?.designation) {
            error = data?.fields?.designation[0]
          }
          this.emitter.emit('alert', {
            type: 'error',
            content: error,
          })
        }
      }
    },
    /**
     * Update a profile
     */
    async updateProfile() {
      const response = await this.fetchService.put(`profil/${this.profile.id}`, {
        uid: this.helperService.generateUid(this.form.designation),
        designation: this.form.designation,
      })
      const meta = await response.meta

      if (meta.success) {
        const permUpdated = await this.updateProfilePermissions()
        const profEntType = await this.updateProfileEntityTypes()

        if (permUpdated && profEntType) {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le profil a bien été modifié.',
          })
          this.$router.push({
            name: 'roleProfile',
            params: {
              id: this.profile.id,
            },
          })
        }
      } else {
        this.formService.handleApiError(response)
      }
    },

    /**
     * Update a profile permissions
     *
     * @param profileId
     */
    async updateProfilePermissions(profileId) {
      let droits = Object.values(this.$store.state.roleProfile.permission.data).map(
        (key) => ({
          uid: key.uid,
        }),
      )

      droits = droits.filter((droit) => 'uid' in droit && droit.uid)

      const response = await this.fetchService.put(`profil/${this.profile.id ?? profileId}/droit`, {
        droits,
      })

      const meta = await response.meta

      if (meta.success) {
        this.$store.dispatch(
          'roleProfile/selection',
          [],
        )
      }

      return meta.success
    },

    /**
     * Update a profile permissions
     *
     * @param profileId
     */
    async updateProfileEntityTypes(profileId) {
      const entities = Object.values(this.form.entitySelection).map((item) => ({ uid: item.key }))

      const response = await this.fetchService.put(`profil/${this.profile.id ?? profileId}/entite_type`, {
        entite_types: entities,
      })

      const meta = await response.meta

      return meta.success
    },

    /**
     * Delete the profile
     */
    deleteProfile() {
      this.fetchService.delete(`profil/${this.profile.id}`).then(() => {
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Le profil a bien été supprimé.',
        })

        this.emitter.emit('list-refresh')
        this.$router.push({ name: 'roleProfiles' })
      },
      (responseError) => {
        this.formService.handleApiError(responseError)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs {}

.tabs--sidebar {
  margin-top: $gutter-double;

  @include bp('sm') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $gutter-half;
  }

  @include bp('md') {
    grid-template-columns: 1fr 2fr;
  }
}

.tabs-content {

  .tabs--sidebar & {
    border-radius: $border-radius-base;
    @include shadow(1);
  }
}

.tabs-togglers {

  .tabs--sidebar & {
    >.tab-toggler {
      white-space: initial;
      padding-left: 2.4rem;
      text-align: left;

      @include bp('sm') {
        display: block;
        width: 100%;

        &.tab-toggler--child {
          margin-left: 2.4rem;
          width: calc(100% - 2.4rem);
        }
      }

      &::before {
        content: "";
        display: block;
        @include size(1.2rem);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid $color-gray-light;
        border-radius: 50%;
      }

      &::after {
        @include bp('sm') {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;
          background-color: $color-gray-lighter;
        }
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &.router-link-active {
        color: $color-primary-lighty;

        &::before {
          background-color: $color-primary-lighty;
          border-color: $color-primary-lighty;
        }
      }
    }
  }
}

.grid {
  margin-bottom: $gutter-half;
}

.grid-col {
  grid-column: span 2;
}
</style>
