<template>
  <form @submit.prevent="onSubmit()">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
        <Btn
          v-if="helperService.userHasPermission('entite_add') && $route.name !== 'protocolsShare'"
          class="action-btn"
          icon="plus"
          color="primary"
          text="Ajouter un type de valeur d'étiquette"
          :to="{
            name: 'statutLabelValueAdd',
          }"
        />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>
    <div class="view-body">
      <Section class="section section--list">
        <Search
          v-model="search"
          returnObject
          :searchEnpoint="list.api"
          searchPlaceholder="Rechercher un gabarit"
        >
          <template v-slot:suggestions="{ item }">
            <router-link
              :to="{ name: 'dictionnaire/type/etiquette/valeur', params: { id: item.id } }"
            >
              {{ item.designation }}
            </router-link>
          </template>
        </Search>

        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
        >
          <template v-slot:group-actions="{ selectedItems }">
            <Btn
              text="Supprimer"
              icon="trash-alt"
              color="default"
              @click="openModal('deleteItems', selectedItems)"
            />
          </template>

          <template v-slot:special="{ item }">
            {{ item.special ? 'Oui' : 'Non' }}
          </template>

          <template v-slot:format_uid="{ item }">
            {{ item.format }}
          </template>

          <template v-slot:actions="{ item }">
            <Btn
              v-if="$route.name === 'protocolsShare'"
              icon="thumbs-up"
              grow
              hollow
              color="secondary"
              @click="openModal('protocolChoice', item)"
            />
            <ToolDropdown>
              <MenuItem
                :item-data="{
                  label: 'Consulter',
                  iconName: 'visibility',
                  route: {
                    name: 'templateLabel',
                     params: {
                      id: item.id,
                     },
                    }
                 }"
              />
              <MenuItem
                :item-data="{
                  label: 'Éditer/supprimer',
                  iconName: 'create',
                  route: {
                    name: 'templateLabelEdit',
                     params: {
                      id: item.id,
                     },
                    }
                 }"
              />
            </ToolDropdown>

          </template>
        </List>
      </Section>
    </div>
  </form>

  <!-- Modals -->
  <!-- Groupe d'actions de suppression -->
  <Modal
    title="Supprimer des protocoles&nbsp;?"
    :active="modal.deleteItems"
    :data="modalData"
    @modal-close="modal.deleteItems = false"
  >
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer
        {{ data.length }} protocoles&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItems = false" />
      <Btn
        text="Supprimer"
        @click="deleteItems(data)"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import Search from '@/components/list/Search.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'

export default {
  name: 'TemplatesLabelView',

  components: {
    MenuItem,
    ToolDropdown,
    Modal,
    Search,
    Pagination,
    List,
    Btn,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      search: {
        attributs: ['designation'],
        filters: {
          // Object
          culture: {
            type: 'object',
            key: 'id',
            values: [],
            label: 'Cultures',
          },
          statut: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Statut',
          },
          thematique: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Thématique',
          },
          type: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Type',
          },
        },
      },

      list: {
        api: {
          endpoint: 'dictionnaire/type/etiquette/valeur',
          params: {
            sort: 'designation.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Désignation', dataField: 'designation' },
          { label: 'Spécial' },
          { label: 'Format' },
        ],
        col: ['designation', 'special', 'format_uid'],
        key: 'id',
      },

      modalData: {},
      modal: {
        deleteItems: false,
      },
    }
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
  },
}
</script>

<style scoped>
</style>
