<template>
  <div class="view view--references">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          round
          grow
          icon="arrow-left"
          :to="{ name: 'mails' }"
        />
        <h1 class="page-title">
          {{ pageTitle }} - {{ mail.objet }} - identifiant: {{ mail.id }}
        </h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>
          <div class="grid">
            <div class="tmp-col">
              <KeyValue label="ID" :value="mail.id" />
              <KeyValue label="Destinataire" :value="mail.destinataire" />
              <KeyValue
                label="Date de création"
                :value="mail.date_creation"
                type="date"
              />
            </div>
            <div class="tmp-col">
              <KeyValue label="Objet" :value="mail.objet" />
              <KeyValue label="Expéditeur" :value="mail.expediteur" />
              <KeyValue
                label="Date d'envoi"
                :value="mail.date_envoi"
                type="date"
              />
            </div>
          </div>
        </Container>
      </Section>
      <Section>
        <Container>
          <KeyValue label="Contenu">
            <iframe class="iframe--mail" :srcdoc="mail?.contenu" frameborder="0"></iframe>
          </KeyValue>
        </Container>
      </Section>
    </div>
  </div>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import KeyValue from '@/components/form/KeyValue.vue'
// import 'prism-es6/components/prism-markup-templating'
// import 'prism-es6/components/prism-json'

export default {
  name: 'MailView',

  components: {
    Btn,
    KeyValue,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      mail: {},
    }
  },

  mounted() {
    this.getMail()
  },

  methods: {
    getMail() {
      this.fetchService
        .get(`utilitaire/mail/${this.$route.params.id}`)
        .then((response) => {
          this.mail = response.data
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.iframe--mail {
  height: 80rem;
  width: 100%;
}
</style>
