<template>
<form @submit.prevent="onSubmit()">
    <div class="view view--culture--add">
      <div class="view-header" v-if="pageTitle">
        <div class="view-header__footer">
          <Btn
            class="back-btn"
            round
            grow
            icon="arrow-left"
            :to="{ name: 'referential', params }"
          />
          <h1 class="page-title">
            {{ title }}
          </h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>
            <div class="grid">
              <div class="tmp-col">
                <Input id="nom" label="Désignation" required/>
              </div>
              <div class="tmp-col">
                <SelectExtended
                      id="famille"
                      search
                      required
                      label="Famille"
                      optionKey="uid"
                      optionValue="nom"
                      apiEndpoint="dictionnaire/famille/culture"
                />
              </div>
            </div>
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <Btn text="Annuler" :to="{ name: 'referential', params }" />
        <Btn btnType="submit" text="Enregistrer" color="primary" />
      </div>
    </div>
  </form>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'CultureFamilyItemAddEditView',

  components: {
    Btn,
    Input,
    Section,
    Container,
    SelectExtended,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      nom: this.yup.string().required(),
      famille: this.yup.object().required(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleItem(values, this.action)
    })

    return {
      type: null,
      id: null,
      endpoint: null,
      dataEndpoint: null,
      title: null,
      method: null,
      data: {},
      params: {},
      onSubmit,
      errors,
      isSubmitting,
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  created() {
    this.type = this.$route.params.type
    this.id = this.$route.params.id

    if (this.$route.params.id_value) {
      this.title = 'Cultures - modifier une valeur'
    } else {
      this.title = 'Cultures - ajouter une valeur'
    }

    this.endpoint = 'culture'
    this.params = {
      type: this.type,
    }

    this.method = 'post'
    if (this.$route.params.id_value) {
      this.endpoint = `${this.endpoint}/${this.$route.params.id_value}`
      this.method = 'put'
      this.getData()
    }
  },

  methods: {
    getData() {
      // get data from API
      this.emitter.emit('open-loader')
      this.fetchService.get(this.endpoint).then(
        (response) => {
          this.data = response.data
          // Populate schema with data
          this.schema = this.formService.populateShema(this.data)
          this.schema.famille = { key: this.data.famille.uid, value: this.data.famille.nom }
          // set form values
          this.formService.setFormValues(this.schema)
          this.emitter.emit('close-loader')
        },
        () => {
          this.emitter.emit('close-loader')
        },
      )
    },

    handleItem(values) {
      const postParams = {}
      postParams.uid = this.helperService.generateUid(values.nom)
      postParams.nom = values.nom
      postParams.famille_uid = values.famille.key

      this.fetchService[this.method](this.endpoint, postParams).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La valeur a bien été ajoutée.',
          })
          const { params } = this
          this.$router.push({ name: 'referential', params })
        },
        (responseError) => {
          let content = ''
          if ('errors' in responseError) {
            content = responseError.errors
            this.formService.setFieldError('valeur', ' ')
          } else {
            content = `${responseError}`
          }
          this.emitter.emit('alert', {
            type: 'error',
            content,
          })
        },
      )
    },
  },
}
</script>

<style scoped>
</style>
