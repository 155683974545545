<template>
  <div class="view view--dictionary">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">

        <Pagination />
        <Btn
          v-if="type !== 'medias'"
          class="action-btn"
          icon="plus"
          color="primary"
          text="Ajouter un élément"
          :to="getRouteParams()"
        />

      </div>
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          round
          grow
          icon="arrow-left"
          :to="{ name: 'references' }"
        />
        <h1 class="page-title">{{ pageTitle }} - {{ title }}</h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section section--list">
        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
        >
          <template v-slot:group-actions="{ selectedItems }">
            <Btn
              text="Supprimer"
              hollow
              @click="openModal('deleteItems', selectedItems)"
            />
          </template>

          <!-- Cultures -->
          <template v-if="type === 'cultures'" v-slot:famille="{ item }">
            {{ item.famille.nom }}
          </template>

          <!-- Composants -->
          <template v-if="type === 'composants'" v-slot:produit_type="{ item }">
            {{ item.produit_type?.designation }}
          </template>
          <template v-if="type === 'composants'" v-slot:thematique="{ item }">
            {{ item.thematique.designation }}
          </template>

          <!-- Medias -->
          <template v-if="type === 'medias' || type === 'pictograms'" v-slot:taille="{ item }">
            {{ fileService.formatBytes(type === 'pictograms' ? item.media.taille : item.taille) }}
          </template>
          <template v-if="type === 'medias'" v-slot:type="{ item }">
            {{ item.type.nom }}
          </template>
          <template v-if="type === 'medias'" v-slot:utilisateur="{ item }">
            {{ item.utilisateur.identifiant }}
          </template>

          <!-- Stade -->
          <template v-if="type === 'stades'" v-slot:famille_culture="{ item }">
            {{ item?.famille_culture?.nom }}
          </template>

          <template v-slot:actions="{ item }">
            <Btn
              v-if="type === 'file-categories'"
              icon="grip-vertical"
              grow
              round
              :to="{
                name: 'fileSubCategories',
                params: {
                  id: item.id
                }
              }"
            />
            <Btn
              icon="trash-alt"
              grow
              round
              @click="openModal('deleteItem', item)"
            />
            <Btn
              v-if="type !== 'medias'"
              icon="create"
              grow
              round
              :to="getRouteParams(item.id, 'edit')"
            />
          </template>
        </List>

        <!-- Modals modalDelete-->
        <Modal
          title="Supprimer l'élément&nbsp;?"
          :active="modal.deleteItem"
          :data="modalData"
          @modal-close="modal.deleteItem = false"
        >
          <template v-slot:modal-body="{ data }">
            <p>
              Voulez vous vraiment supprimer l'élément
              <b>{{ data.valeur }}</b
              >&nbsp;?
            </p>
          </template>
          <template v-slot:modal-footer="{ data }">
            <Btn text="Annuler" @click="modal.deleteItem = false" />
            <Btn
              text="Supprimer"
              @click="deleteItems([data.id])"
              icon="trash-alt"
              color="primary"
            />
          </template>
        </Modal>
        <!-- Modals modalDelete-->
        <Modal
          title="Supprimer des éléments&nbsp;?"
          :active="modal.deleteItems"
          :data="modalData"
          @modal-close="modal.deleteItems = false"
        >
          <template v-slot:modal-body="{ data }">
            <p>
              Voulez vous vraiment supprimer {{ data.length }} éléments &nbsp;?
            </p>
          </template>
          <template v-slot:modal-footer="{ data }">
            <Btn text="Annuler" @click="modal.deleteItems = false" />
            <Btn
              text="Supprimer"
              @click="deleteItems(data)"
              icon="trash-alt"
              color="primary"
            />
          </template>
        </Modal>
      </Section>
    </div>
    <!-- .view-body -->
  </div>
  <!-- .view -->
</template>

<script>
import Section from '@/components/layout/Section.vue'
import List from '@/components/list/List.vue'
import Btn from '@/components/base/Btn.vue'
import Modal from '@/components/layout/Modal.vue'
import Pagination from '@/components/list/Pagination.vue'

export default {
  name: 'ReferentialView',

  components: {
    Pagination,
    Section,
    List,
    Btn,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      title: null,
      params: {},
      modalData: {},
      modal: {
        deleteItem: false,
        deleteItems: false,
      },
      deleteEndpoint: null,
      list: {
        api: {
          endpoint: null,
          params: {
            sort: 'id',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [{ label: 'Designation', dataField: 'valeur', grow: true }],
        col: ['valeur'],
        key: 'id',
        meta: {},
      },
    }
  },

  created() {
    this.type = this.$route.params.type
    this.id = this.$route.params.id

    switch (this.type) {
      case 'referentiel':
      default:
        this.getReferentialName()
        this.list.api.endpoint = `dictionnaire/referentiel/${this.$route.params.id}/valeur`
        this.deleteEndpoint = this.list.api.endpoint
        this.params = {
          type: this.type,
          id: this.$route.params.id,
        }
        break
      case 'unites':
        this.title = 'Unités'
        this.list.api.endpoint = 'unite'
        this.deleteEndpoint = this.list.api.endpoint
        this.params = {
          type: this.type,
        }
        break
      case 'familles-de-produits':
        this.title = 'Familles de produits'
        this.list.api.endpoint = 'dictionnaire/famille/produit'
        this.list.api.params.sort = 'nom.ASC'
        this.deleteEndpoint = this.list.api.endpoint
        this.params = {
          type: this.type,
        }
        this.list.col = ['nom', 'uid']
        this.list.headers = [
          { label: 'Designation', dataField: 'nom', sorted: true },
          { label: 'Code', dataField: 'uid' },
        ]
        break
      case 'familles-de-cultures':
        this.title = 'Familles de cultures'
        this.list.api.endpoint = 'dictionnaire/famille/culture'
        this.list.api.params.sort = 'nom.ASC'
        this.deleteEndpoint = this.list.api.endpoint
        this.params = {
          type: this.type,
        }
        this.list.col = ['nom', 'uid']
        this.list.headers = [
          { label: 'Designation', dataField: 'nom', sorted: true },
          { label: 'Code', dataField: 'uid' },
        ]
        break
      case 'cultures':
        this.title = 'Cultures'
        this.list.api.endpoint = 'culture'
        this.list.api.params.sort = 'nom.ASC'
        this.deleteEndpoint = this.list.api.endpoint
        this.params = {
          type: this.type,
        }
        this.list.col = ['nom', 'uid', 'famille']
        this.list.headers = [
          { label: 'Designation', dataField: 'nom', sorted: true },
          { label: 'Code' },
          { label: 'Famille' },
        ]
        break
      case 'composants':
        this.title = 'Composants'
        this.list.api.endpoint = 'composant'
        this.list.api.params.sort = 'nom.ASC'
        this.deleteEndpoint = this.list.api.endpoint
        this.params = {
          type: this.type,
        }
        this.list.col = ['nom', 'produit_type', 'thematique']
        this.list.headers = [
          { label: 'Designation', dataField: 'nom', sorted: true },
          { label: 'Type de produit' },
          { label: 'Thématique' },
        ]
        break
      case 'thematiques':
        this.getThematiqueName()
        this.title = 'Sous-themes'
        this.list.api.endpoint = `dictionnaire/thematique/${this.$route.params.id}/sous-thematique`
        this.deleteEndpoint = this.list.api.endpoint
        this.list.api.params.sort = 'nom.ASC'
        this.params = {
          type: this.type,
          id: this.$route.params.id,
        }
        this.list.col = ['nom', 'uid']
        this.list.headers = [
          { label: 'Designation', dataField: 'nom', sorted: true },
          { label: 'Code' },
        ]
        break
      case 'pictograms':
        this.title = 'Pictogramme'
        this.list.api.endpoint = 'pictogramme'
        this.deleteEndpoint = this.list.api.endpoint
        this.params = {
          type: this.type,
        }
        this.list.col = ['nom', 'taille']
        this.list.headers = [
          { label: 'Designation', dataField: 'nom' },
          { label: 'Taille' },
        ]
        break
      case 'medias':
        this.title = 'Médias'
        this.list.api.endpoint = 'utilitaire/media'
        this.deleteEndpoint = this.list.api.endpoint
        this.params = {
          type: this.type,
        }
        this.list.col = ['nom', 'type', 'utilisateur', 'taille']
        this.list.headers = [
          { label: 'Designation', dataField: 'nom' },
          { label: 'Type' },
          { label: 'Utilisateur rattaché' },
          { label: 'Taille' },
        ]
        break
      case 'file-categories':
        this.title = 'Catégories de fichier'
        this.list.api.endpoint = 'dictionnaire/categorie/fichier'
        this.deleteEndpoint = this.list.api.endpoint
        this.params = {
          type: this.type,
        }
        this.list.col = ['nom']
        this.list.headers = [
          { label: 'Description', dataField: 'description' },
        ]
        break
      case 'stades':
        this.title = 'Stade'
        this.list.api.endpoint = 'dictionnaire/stade'
        this.deleteEndpoint = this.list.api.endpoint
        this.params = {
          type: this.type,
        }
        this.list.col = ['designation', 'code_bbch', 'famille_culture']
        this.list.headers = [
          { label: 'Designation', dataField: 'designation' },
          { label: 'Code BBCH' },
          { label: 'Famille de culture' },
        ]
        break
    }
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
    getReferentialName() {
      this.fetchService
        .get(`dictionnaire/referentiel/${this.$route.params.id}`)
        .then((response) => {
          this.title = response.data.designation
        })
    },
    getThematiqueName() {
      this.fetchService
        .get(`dictionnaire/thematique/${this.$route.params.id}`)
        .then((response) => {
          this.title = response.data.designation
        })
    },
    getRouteParams(id = null, action = 'add') {
      const params = (name, customKey = 'id_value') => ({
        name: `${name}${action === 'add' ? 'Add' : 'Edit'}`,
        params: {
          ...this.params,
          ...(action === 'edit' && { [customKey]: id }),
        },
      })

      let returnParams
      switch (this.type) {
        case 'familles-de-produits':
          returnParams = params('productFamily')
          break
        case 'familles-de-cultures':
          returnParams = params('cultureFamily')
          break
        case 'cultures':
          returnParams = params('culture')
          break
        case 'thematiques':
          returnParams = params('thematique', 'tid')
          break
        case 'composants':
          returnParams = params('composant', 'id')
          break
        case 'pictograms':
          returnParams = params('pictogram', 'id')
          break
        case 'file-categories':
          returnParams = params('fileCategory', 'id')
          break
        case 'stades':
          returnParams = params('stade', 'id')
          break
        default:
          returnParams = params('referential')
          break
      }

      return returnParams
    },
    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        this.fetchService.delete(`${this.deleteEndpoint}/${item.id}`).then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'L\'élément a été supprimé.',
            })
            this.emitter.emit('list-refresh')
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )
      })

      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
      this.modal.deleteItem = false
      this.modal.deleteItems = false
    },
  },
}
</script>

<style scoped>
</style>
