<template>
  <div class="view view--references">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section section--list">
        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
          :disable-checkbox="true"
        >
          <template v-slot:date="{ item }">
            {{ new Date(item.date).toLocaleString() }}
          </template>

          <template v-slot:type="{ item }">
            {{ item.type.uid }}
          </template>

          <template v-slot:actions="{ item }">
            <Btn
              icon="visibility"
              default
              :to="{
                name: 'log',
                params: {
                  id: item.id,
                },
              }"
              round
              grow
            />
          </template>
        </List>
      </Section>
    </div>
  </div>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import List from '@/components/list/List.vue'
import Btn from '@/components/base/Btn.vue'
import Pagination from '@/components/list/Pagination.vue'

export default {
  name: 'LogsView',

  components: {
    Pagination,
    Btn,
    List,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      list: {
        api: {
          endpoint: 'log',
          params: {
            sort: 'date.DESC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Date', dataField: 'date' },
          { label: 'Types' },
          { label: 'Objets', dataField: 'objet' },
        ],
        col: ['date', 'type', 'objet'],
        key: 'id',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
