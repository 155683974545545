<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--culture--add">
      <div class="view-header" v-if="pageTitle">
        <div class="view-header__footer">
          <Btn
            class="back-btn"
            round
            grow
            icon="arrow-left"
            :to="{ name: 'referential', params }"
          />
          <h1 class="page-title">
            {{ title }}
          </h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>
            <div class="grid">
              <div class="tmp-col">
                <SelectExtended
                  id="produit_type_uid"
                  search
                  label="Type de produit"
                  optionKey="uid"
                  optionValue="designation"
                  apiEndpoint="dictionnaire/type/produit"
                />
              </div>
              <div class="tmp-col">
                <SelectExtended
                  id="thematique_uid"
                  search
                  label="Thématique"
                  optionKey="uid"
                  optionValue="designation"
                  apiEndpoint="dictionnaire/thematique"
                />
              </div>
              <div class="tmp-col">
                <Input id="nom" label="Désignation" required/>
              </div>
              <div class="tmp-col">
                <Input id="observation" label="Observation" />
              </div>
            </div>
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <Btn text="Annuler" :to="{ name: 'referential', params }" />
        <Btn btnType="submit" text="Enregistrer" color="primary" />
      </div>
    </div>
  </form>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'ComposantItemAddEditView',

  components: {
    Btn,
    Input,
    Section,
    Container,
    SelectExtended,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      produit_type_uid: this.yup.object().nullable(),
      thematique_uid: this.yup.object().nullable(),
      nom: this.yup.string().required(),
      observation: this.yup.string(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleItem(values, this.action)
    })

    return {
      type: null,
      id: null,
      endpoint: null,
      dataEndpoint: null,
      title: null,
      method: null,
      data: {},
      params: {},
      onSubmit,
      errors,
      isSubmitting,
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  created() {
    this.type = this.$route.params.type
    this.id = this.$route.params.id

    if (this.id) {
      this.title = 'Composant - modifier une valeur'
    } else {
      this.title = 'Composant - ajouter une valeur'
    }

    this.endpoint = 'composant'
    this.params = {
      type: this.type,
    }

    this.method = 'post'
    if (this.id) {
      this.endpoint = `${this.endpoint}/${this.id}`
      this.method = 'put'
      this.getData()
    }
  },

  methods: {
    getData() {
      // get data from API
      this.emitter.emit('open-loader')
      this.fetchService.get(this.endpoint).then(
        (response) => {
          this.data = response.data
          // Populate schema with data
          this.schema = this.formService.populateShema(this.data)

          if (this.data.produit_type !== null) {
            this.schema.produit_type_uid = {
              key: this.data.produit_type.uid,
              value: this.data.produit_type.designation,
            }
          }
          if (this.data.thematique !== null) {
            this.schema.thematique_uid = {
              key: this.data.thematique.uid,
              value: this.data.thematique.designation,
            }
          }
          // set form values
          this.formService.setFormValues(this.schema)
          this.emitter.emit('close-loader')
        },
        () => {
          this.emitter.emit('close-loader')
        },
      )
    },

    handleItem(values) {
      // eslint-disable-next-line no-param-reassign
      values.thematique_uid = values.thematique_uid?.key
      // eslint-disable-next-line no-param-reassign
      values.produit_type_uid = values.produit_type_uid?.key

      this.fetchService[this.method](this.endpoint, values).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le composant a bien été ajouté.',
          })
          const { params } = this
          this.$router.push({ name: 'referential', params })
        },
        (responseError) => {
          let content = ''
          if ('errors' in responseError) {
            content = responseError.errors
            this.formService.setFieldError('valeur', ' ')
          } else {
            content = `${responseError}`
          }
          this.emitter.emit('alert', {
            type: 'error',
            content,
          })
        },
      )
    },
  },
}
</script>

<style scoped>
</style>
